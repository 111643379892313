import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import {
	ICreateReleasePlaylistRequest,
	IInstructorReleasePlaylistReadModel,
	IInstructorReleasePlaylistTrackReadModel,
	IRecentlySharedCommunityPlaylistsReadModel,
} from './models/instructor-release-playlist.types';
import { IGfxInstructorReleasePlaylistSearchReadModel, IGfxSearchReadModel } from '../search/models/search.types';

@Injectable()
export class InstructorReleasePlaylistService {
	constructor(private http: HttpClient) {}

	async getMyReleasePlaylists(): Promise<IInstructorReleasePlaylistReadModel[]> {
		return await lastValueFrom(
			this.http.get<IInstructorReleasePlaylistReadModel[]>(`/api/release-playlists/instructors`),
		);
	}

	async getMyReleasePlaylistsByProgram(
		instructorId: string,
		programId: string,
	): Promise<IInstructorReleasePlaylistReadModel[]> {
		return await lastValueFrom(
			this.http.get<IInstructorReleasePlaylistReadModel[]>(
				`/api/release-playlists/instructors/${instructorId}/programs/${programId}`,
			),
		);
	}

	async getReleasePlaylist(instructorReleasePlaylistId: string): Promise<IInstructorReleasePlaylistReadModel> {
		return await lastValueFrom(
			this.http.get<IInstructorReleasePlaylistReadModel>(`/api/release-playlists/${instructorReleasePlaylistId}`),
		);
	}

	async getCommunityReleasePlaylist(
		instructorReleasePlaylistId: string,
	): Promise<IGfxInstructorReleasePlaylistSearchReadModel> {
		return await lastValueFrom(
			this.http.get<IGfxInstructorReleasePlaylistSearchReadModel>(
				`/api/community/playlists/${instructorReleasePlaylistId}`,
			),
		);
	}

	async getRecentlySharedCommunityPlaylists(
		numberOfRecords: number | undefined = 25,
		pageOffset: number | undefined = 1,
		includedProgramIds: string[] | undefined = undefined,
	): Promise<IGfxInstructorReleasePlaylistSearchReadModel[]> {
		const programIds = (includedProgramIds ?? []).join(',');

		return await lastValueFrom(
			this.http.get<IGfxInstructorReleasePlaylistSearchReadModel[]>(`/api/community/playlists/`, {
				params: { numberOfRecords: numberOfRecords, pageOffset: pageOffset, includedProgramIds: programIds },
			}),
		);
	}

	async getSharedCommunityPlaylistsForInstructor(
		instructorId: string,
		numberOfRecords: number,
		pageOffset: number,
	): Promise<IRecentlySharedCommunityPlaylistsReadModel[]> {
		return await lastValueFrom(
			this.http.get<IRecentlySharedCommunityPlaylistsReadModel[]>(
				`/api/community/playlists/instructors/${instructorId}`,
				{
					params: {
						numberOfRecords: numberOfRecords,
						pageOffset: pageOffset,
					},
				},
			),
		);
	}

	async createReleasePlaylist(request: ICreateReleasePlaylistRequest): Promise<IInstructorReleasePlaylistReadModel> {
		return lastValueFrom(this.http.post<IInstructorReleasePlaylistReadModel>('/api/release-playlists', request));
	}

	async copyReleaseToPlaylist(
		playlistName: string,
		playlistDescription: string | null,
		releaseId: string,
	): Promise<IInstructorReleasePlaylistReadModel> {
		return await lastValueFrom(
			this.http.post<IInstructorReleasePlaylistReadModel>('/api/release-playlists/create-from-release', {
				releaseId: releaseId,
				playlistName: playlistName,
				playlistDescription: playlistDescription,
			}),
		);
	}

	async copyCommunityPlaylistToMyPlaylists(
		sourcePlaylistId: string,
		playlistName: string,
		playlistDescription: string | null,
		isSharedPublicly: boolean,
	): Promise<IInstructorReleasePlaylistReadModel> {
		return await lastValueFrom(
			this.http.post<IInstructorReleasePlaylistReadModel>('/api/release-playlists/copy', {
				sourceInstructorReleasePlaylistId: sourcePlaylistId,
				playlistName: playlistName,
				playlistDescription: playlistDescription,
				isSharedPublicly: isSharedPublicly,
			}),
		);
	}

	renameInstructorReleasePlaylist(
		instructorReleasePlaylistId: string,
		newPlaylistName: string,
		newPlaylistDescription: string | null,
	): Observable<void> {
		return this.http.put<void>(`/api/release-playlists/${instructorReleasePlaylistId}/rename`, {
			newPlaylistName: newPlaylistName,
			newPlaylistDescription: newPlaylistDescription,
		});
	}

	async addTrackToReleasePlaylist(
		instructorReleasePlaylistId: string,
		releaseTrackId: string,
		ordinal: number,
	): Promise<IInstructorReleasePlaylistTrackReadModel> {
		return await lastValueFrom(
			this.http.post<IInstructorReleasePlaylistTrackReadModel>(
				`/api/release-playlists/${instructorReleasePlaylistId}/release-tracks/`,
				{ releaseTrackId, ordinal },
			),
		);
	}

	async replaceTrackOnReleasePlaylist(
		instructorReleasePlaylistId: string,
		instructorReleasePlaylistTrackId: string,
		releaseTrackId: string,
	): Promise<IInstructorReleasePlaylistTrackReadModel> {
		return await lastValueFrom(
			this.http.put<IInstructorReleasePlaylistTrackReadModel>(
				`/api/release-playlists/${instructorReleasePlaylistId}/release-tracks/${instructorReleasePlaylistTrackId}`,
				{ releaseTrackId },
			),
		);
	}

	async removeTrackFromReleasePlaylist(
		instructorReleasePlaylistId: string,
		instructorReleasePlaylistTrackId: string,
	): Promise<void> {
		await lastValueFrom(
			this.http.delete<IInstructorReleasePlaylistTrackReadModel>(
				`/api/release-playlists/${instructorReleasePlaylistId}/release-tracks/${instructorReleasePlaylistTrackId}`,
			),
		);
	}

	async changeOrdinalOfTrack(
		instructorReleasePlaylistId: string,
		instructorReleasePlaylistTrackId: string,
		ordinal: number,
	): Promise<IInstructorReleasePlaylistTrackReadModel> {
		return await lastValueFrom(
			this.http.put<IInstructorReleasePlaylistTrackReadModel>(
				`/api/release-playlists/${instructorReleasePlaylistId}/release-tracks/${instructorReleasePlaylistTrackId}/ordinal`,
				{ ordinal },
			),
		);
	}

	removePlaylist(instructorReleasePlaylistId: string): Observable<void> {
		return this.http.delete<void>(`/api/release-playlists/${instructorReleasePlaylistId}`);
	}

	async sharePublicly(instructorReleasePlaylistId: string): Promise<void> {
		return await lastValueFrom(this.http.put<void>(`/api/release-playlists/${instructorReleasePlaylistId}/share`, {}));
	}

	async unsharePublicly(instructorReleasePlaylistId: string): Promise<void> {
		return await lastValueFrom(
			this.http.put<void>(`/api/release-playlists/${instructorReleasePlaylistId}/unshare`, {}),
		);
	}
}
